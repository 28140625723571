/*To Do Add*/
.flex-container {
    display: flex;
    width: 50%;
    justify-content: center;
    flex-direction: column;
    text-align:center;
    margin:auto;
    padding:50px 0;
    color:white;
  }
  
  .nue-box {
    box-shadow: 10px 10px 15px #300D6E, -5px -5px 15px #905BEC;
    border-radius: 10px;
    padding: 20px 0;
  }
  
  /* button */
  .to-do-btn {
    background-color: #E9DFFB;
    color:#190934;
    border: 1px solid#00d2c6;
    border-radius: 3px;
    width: 20%;
  }
  
  /* To Do Active Styles*/
  .open-todos {
    background-color: #E9DFFB;
    border: 2px solid black;
    margin-top: 5px;
    color: #190934;
    text-align: left;
    padding: 0 20px;
  }
  .active-todos{
    border: 1px solid lightgrey;
    background-color: #300D6E;
    border-radius: 10px;
    padding-bottom:10px;
  
  }
  .todo-check-box:hover{
    cursor: pointer;
  }
  
  /* Completed Task Styles */
  
  .show-completed {
    display: flex;
    flex-direction: column;
    border: 1px solid darkgrey;
    background-color: #E9DFFB;
    border-radius: 10px;
    padding-bottom:10px;
    
  }
  .show-completed > div {
    background-color: bisque;
    text-align: left;
    padding: 5px 20px;
    color: #E9DFFB;
    margin: 2px 0;
  }
  .hide-completed {
    display: none;
  }
  