.flex-container{
    display:flex;
    justify-content: center
}

.flex-container > div{
    background-color: #f1f1f1;
    margin: 10px;
    padding: 20px;
    font-size: 30px;
    color: black;
}

.joke-box{
    padding: 10px 20px;
    border: 2px solid black;
    margin: 10px;
}

.setup{
    font-style: italic;    
}

.punchline{
    font-weight: bold;
}