/* styles for portfolio.js*/
figure {
  width: auto;
  height: auto;
  margin: 0;
  padding: 1em .25em;  
  overflow: hidden;
}        
/* Zoom In #1 */
.hover01 figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.hover01 figure:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);   
}